import { isPlainObject } from '@reduxjs/toolkit';
import { getComponentFaultTitle, getFaultTitle, getMainFaultTitle, getSubFaultTitle } from './faultConstants';
import { ageGroupKey, atjResult, averageAgeKey, DEFAULT_SKIP_TOO_FEW_INSPECTIONS_LIMIT, faultGroupKey, faultsTotalKey, inspectionsTotalKey, resultsTotalKey, rootKey, separatorKey, totalKey, trafiExpectationValues } from './faultReportConstants';
import { formatDecimal } from './numberFunctions';

export const isReferenceGroup = (key) => key.startsWith('_');
export const isMainGroup = (key) => ! isReferenceGroup(key);
export const isTotalGroup = (key) => key.startsWith(faultGroupKey.total);
export const getReferenceGroupLabel = (key, t) => isReferenceGroup(key) ? t(key.replace('_', 'faultReportView.') + 'ReferenceGroup') : key;
export const getReportGroupLabel = (reportInfo, users) => {
  const user = users[reportInfo?.userId] ?? null;

  return user ? `${user.firstName} ${user.lastName}` : `${reportInfo?.userId ?? '?'}`;
}

export const isFaultKey = (key) => ! key.startsWith('_');
export const isSingleValueRow = (key) => key.startsWith('_') && key !== faultsTotalKey;

export const formatCount = (count) => {
  return formatDecimal(count ?? 0, 0, false);
}

export const formatPercentage = (rate) => {
  return formatDecimal(100 * (rate ?? 0), 2) + ' %';
}

export const formatAverage = (rate) => {
  return formatDecimal(rate ?? 0, 2);
}

export const formatFaultPath = (path) => {
  return path.join('/');
}

export const getFaultPathTitle = (fault, languageId) => {
  const level = fault.path.length;

  const f = {
    mainFaultId: fault.path[0],
    subFaultId: fault.path[1] ?? null,
    componentFaultId: fault.path[2] ?? null,
    faultId: fault.path[3] ?? null,
  }

  if (level === 1) {
    return getMainFaultTitle(f, languageId)
  } else if (level === 2) {
    return getSubFaultTitle(f, languageId)
  } else if (level === 3) {
    return getComponentFaultTitle(f, languageId)
  } else if (level === 4) {
    return getFaultTitle(f, languageId)
  } else {
    return '?';
  }
}

export const getReportRowTitle = (fault, languageId, t) => {
    if (isFaultKey(fault.key)) {
        return getFaultPathTitle(fault, languageId)
    } else {
        return t(fault.key.replace('_', 'faultReportView.'));
    }
}

export const getReportGroupKey = (report, groupBy) => {
  if (report.source === 1 && report.siteId > 0) {
    return faultGroupKey.site;
  } else if (report.source === 1 && report.chainId > 0) {
    return faultGroupKey.chain;
  } else if (report.source === 1) {
    return faultGroupKey.doris;
  } else if (report.source === 2) {
    return faultGroupKey.trafi;
  } else {
    return `u${groupBy.user ? report.userId : 'x'}.s${groupBy.site ? report.siteId : 'x'}.a${report.vehicleAgeGroup ?? 'x'}`;
  }
};

const addTreeFault = (flatList2, path, groupKey, totalKey, fault) => {
  let current = flatList2;
  const currentPath = [];
  for (const pathKey of path) {
    currentPath.push(pathKey);
    const currentKey = formatFaultPath(currentPath);
    if (! current[currentKey]) {
      current[currentKey] = {
        key: currentKey,
        path: Array.from(currentPath),
        faults: {},
        totals: {},
      }
    }
    if (! current[currentKey].totals[groupKey]) {
      current[currentKey].totals[groupKey] = {};
    }
    if (! current[currentKey].totals[groupKey][fault.severity]) {
      current[currentKey].totals[groupKey][fault.severity] = {
        count: 0,
      }
    }
    current[currentKey].totals[groupKey][fault.severity].count += fault.count;
    // total column
    if (totalKey) {
      if (! current[currentKey].totals[totalKey]) {
        current[currentKey].totals[totalKey] = {};
      }
      if (! current[currentKey].totals[totalKey][fault.severity]) {
        current[currentKey].totals[totalKey][fault.severity] = {
          count: 0,
        }
      }
      current[currentKey].totals[totalKey][fault.severity].count += fault.count;
    }
    current = current[currentKey].faults;
  }
}

export const formatFaultReportData = (reportData) => {
  const result = {
    key: rootKey,
    path: [],
    faults: {},
    totals: {},
    reports: {},
  }
  if (! reportData) {
    return result;
  }

  const reportList = Array.from(reportData ?? []).sort((a, b) => (a.source ?? 0) - (b.source ?? 0));

  for (const report of reportList) {
    const { faults: reportFaults, totals: reportTotals, siteId, ...reportInfo } = report;
    const groupKey = getReportGroupKey(report, {user: true});
    const totalKey = report.source > 0 ? null : faultGroupKey.total;

    result.reports[groupKey] = {...reportInfo};

    for (const total of reportTotals) {
      const totalFault = {
          severity: 1,
          count: total.count,
      };

      addTreeFault(result.faults, [resultsTotalKey(total.atjResult)], groupKey, totalKey, totalFault);
      if (total.atjResult === atjResult.rejectedNoBan || total.atjResult === atjResult.drivingBan) {
        addTreeFault(result.faults, [resultsTotalKey(atjResult.rejected)], groupKey, totalKey, totalFault);
      }
      if (total.atjResult !== atjResult.interrupted) {
        addTreeFault(result.faults, [inspectionsTotalKey], groupKey, totalKey, totalFault);
      }

      const averageAgeFault = {
        severity: 1,
        // Multiply number of inspections by average age in years.
        // This will be divided by total number of inspections to get "fault rate"
        // -> The end result is weighted average of average ages, i.e. average age over all inspections
        count: total.count * total.averageAge,
      };

      addTreeFault(result.faults, [averageAgeKey], groupKey, totalKey, averageAgeFault);
    }

    for (const fault of reportFaults) {
      const tpath = [fault.mainFaultId, fault.subFaultId, fault.componentFaultId, fault.faultId].filter(p => p !== null && p !== undefined);

      if (report.source > 0) {
        addTreeFault(result.faults, tpath, groupKey, null, fault);
      } else {
        addTreeFault(result.faults, tpath, groupKey, totalKey, fault);
      }
      addTreeFault(result.faults, [faultsTotalKey], groupKey, totalKey, fault);
    }
  }

  const calculateFaultRate = (inspectionCounts, fault) => {
    if (! fault) {
      return fault;
    }

    for (const key of Object.keys(fault.faults ?? {})) {
      fault.faults[key] = calculateFaultRate(inspectionCounts, fault.faults[key]);
    }

    const faultMin = {};
    const faultMax = {};

    for (const groupKey in fault.totals) {
      const isMainGroupKey = isMainGroup(groupKey)
      const sevTotal = fault.totals[groupKey];
      const inspectionCount = inspectionCounts?.totals?.[groupKey]?.[1]?.count ?? 0;

      for (const sevLevel in sevTotal) {
        const faultTotal = sevTotal[sevLevel];
        if (faultTotal.count) {
          if (fault.key !== inspectionsTotalKey) {
            faultTotal.rate = inspectionCount > 0 ? (faultTotal.count / inspectionCount) : 0;
          }

          if (isMainGroupKey) {
            if (! faultMin[sevLevel]) {
              faultMin[sevLevel] = {};
            }

            if (! faultMax[sevLevel]) {
              faultMax[sevLevel] = {};
            }

            faultMin[sevLevel].count = Math.min(faultTotal.count, faultMin[sevLevel].count ?? faultTotal.count);
            faultMax[sevLevel].count = Math.max(faultTotal.count, faultMax[sevLevel].count ?? faultTotal.count);

            if (fault.key !== inspectionsTotalKey) {
              faultMin[sevLevel].rate = Math.min(faultTotal.rate, faultMin[sevLevel].rate ?? faultTotal.rate);
              faultMax[sevLevel].rate = Math.max(faultTotal.rate, faultMax[sevLevel].rate ?? faultTotal.rate);
            }
          }
        } else if (faultTotal.count === 0) {
          faultTotal.rate = 0;
        }
      }
    }

    fault.totals[faultGroupKey.totalMin] = faultMin;
    fault.totals[faultGroupKey.totalMax] = faultMax;

    return fault;
  }

  return calculateFaultRate(result.faults[inspectionsTotalKey], result);
}

export const calculateFaultReportExtraInfo = (reportData, referenceGroupKey, params) => {
  const {skipTooFewInspectionsLimit = DEFAULT_SKIP_TOO_FEW_INSPECTIONS_LIMIT, withRefData = false} = params;
  const calculateFaultInfo = (inspectionTotals, fault) => {
    if (!fault) {
      return null
    }

    if (fault.key === inspectionsTotalKey) {
      return fault;
    }

    for (const subRowKey of Object.keys(fault.faults ?? {})) {
      const f = calculateFaultInfo(inspectionTotals, fault.faults[subRowKey]);
      fault.faults[subRowKey] = f;

      for (const groupKey in f.totals) {
        if (! fault.totals[groupKey]) {
          fault.totals[groupKey] = {}
        }
        for (const sevLevel in f.totals[groupKey]) {
          if (! fault.totals[groupKey][sevLevel]) {
            fault.totals[groupKey][sevLevel] = {}
          }
          fault.totals[groupKey][sevLevel].allErrors = (fault.totals[groupKey][sevLevel].allErrors ?? 0) + (f.totals[groupKey][sevLevel].allErrors ?? 0);
        }
      }
    }

    const refTotals = fault.totals?.[referenceGroupKey];
    const refInspectionCount = inspectionTotals[referenceGroupKey]?.[1]?.count ?? 0;

    for (const groupKey in fault.totals) {
      const isRefGroup = isReferenceGroup(groupKey);
      const sevTotal = fault.totals[groupKey];

      if (isRefGroup || inspectionTotals?.[groupKey]?.[1]?.tooFew) {
        continue;
      }

      for (const sevLevel in sevTotal) {
        const faultTotal = sevTotal[sevLevel];

        if (! faultTotal.count || ! faultTotal.rate) {
          continue;
        }

        const refCount = refTotals?.[sevLevel]?.count ?? 0;
        const refRate = refInspectionCount > 0 ? (refCount / refInspectionCount) : null;

        if (refRate) {
          if (hasRateDeviated(faultTotal.rate, refRate, sevLevel)) {
            faultTotal.error = 1
            if (withRefData) {
              faultTotal.refRate = refRate;
              faultTotal.refCount = refCount;
              faultTotal.diff = refRate > 0 ? (100 * faultTotal.rate / refRate - 100) : 100;
            }
          }

          if (faultTotal.error) {
            faultTotal.allErrors = (faultTotal.allErrors ?? 0) + faultTotal.error;
          }
        }
      }
    }

    return fault;
  }

  const deepCopyReportData = (src) => {
    if (isPlainObject(src)) {
      const res = {}
      for (const key in src) {
        res[key] = deepCopyReportData(src[key]);
      }
      return res;
    }

    return src;
  }

  const reportDataCopy = deepCopyReportData(reportData);
  const inspectionTotals = reportDataCopy?.faults[inspectionsTotalKey]?.totals ?? {};

  for (const groupKey in inspectionTotals) {
    if (inspectionTotals[groupKey][1]?.count < skipTooFewInspectionsLimit) {
      inspectionTotals[groupKey][1].tooFew = 1;
    }
  }

  return calculateFaultInfo(inspectionTotals, reportDataCopy);
}

export const getFlatFaultList = (rootFault, mapFunc = null) => {
  const flatten = (fault, faultList) => {
    const isRoot = fault?.key === rootKey;
    const subFaultKeys = Object.keys(fault?.faults ?? {}).sort();

    if (! isRoot) {
      const {faults, ...item} = fault;
      faultList.push(mapFunc ? mapFunc(item) : item);
    }

    for (const subKey of subFaultKeys) {
      if (subKey !== separatorKey && fault.faults[subKey]) {
        flatten(fault.faults[subKey], faultList);
      }
    }

    return faultList;
  }

  return flatten(rootFault, []).filter(x => x)
}

export const getRefRate = (groupKey, refKey, level) => {

  const refValue = trafiExpectationValues[groupKey].ref[refKey];
  const stepSize = trafiExpectationValues[groupKey].step[refKey];

  return refValue / 100 + level * stepSize / 100;
}

const getRefLevel = (groupKey, refKey, value) => {

  const refValue = trafiExpectationValues[groupKey].ref[refKey];
  const stepSize = trafiExpectationValues[groupKey].step[refKey];
  const level = Math.min(10, Math.max(-10, (value - refValue) / stepSize));

  return +(level.toFixed(2));
}

export const getRefLevelStatus = level => {
  if (level > 7 || level < -3) {
    return 'error';
  } else if (level > 5 || level < -1) {
    return 'warning';
  } else {
    return 'ok';
  }
}

export const getPercentageDeviationThresholds = (refPercentage, sevLevel, maxPercentage) => {
  switch (Number.parseInt(sevLevel)) {
    case 2:
      return [Math.max(0.8 * refPercentage, refPercentage - 5), Math.min(maxPercentage, refPercentage + 5)];
    case 3:
      return [0.8 * refPercentage, maxPercentage];
    default:
      // severity level 1 and other values, for example reject percentage
      return [Math.max(0.8 * refPercentage, refPercentage - 10), Math.min(maxPercentage, refPercentage + 10)];
  }
}

export const hasPercentageDeviated = (percentage, refPercentage, sevLevel) => {
  const [min, max] = getPercentageDeviationThresholds(refPercentage, sevLevel, 1000);

  return percentage < min || percentage > max;
}

export const hasRateDeviated = (rate, refRate, sevLevel) => {
  return hasPercentageDeviated(rate * 100, refRate * 100, sevLevel);
}

export const formatFaultReportAverages = (averages) => {
  const emptyCounts = () => ({
    inspected: {count: 0},
    rejected: {count: 0},
    severity1: {count: 0},
    severity2: {count: 0},
    averageAge: {count: 0},
    avgLevel: {count: 0},
  });

  const emptyData = (avg) => ({
    chainId: avg.chainId ?? 0,
    siteId: avg.siteId ?? 0,
    userId: avg.userId ?? 0,
    values: {
      [ageGroupKey(0)]: emptyCounts(),
      [ageGroupKey(1)]: emptyCounts(),
      [ageGroupKey(2)]: emptyCounts(),
      [ageGroupKey(3)]: emptyCounts(),
      [ageGroupKey(4)]: emptyCounts(),
      [ageGroupKey(5)]: emptyCounts(),
      [totalKey]: emptyCounts(),
    },
  })

  const addData = (item, avg) => {
    if (avg.atjResult && avg.atjResult !== atjResult.interrupted) {
      item.inspected.count += avg.count
      item.averageAge.count += avg.averageAge * avg.count
    }
    if (avg.atjResult === atjResult.rejectedNoBan || avg.atjResult === atjResult.drivingBan) {
      item.rejected.count += avg.count
    }

    if (avg.severity === 1) {
      item.severity1.count += avg.count
    } else if (avg.severity === 2) {
      item.severity2.count += avg.count
    }
  }

  const result = {
    [totalKey]: emptyData({}),
  };

  for (const avg of averages) {
    const userKey = `${avg.chainId ?? 0}-${avg.siteId ?? 0}-${avg.userId ?? 0}`;
    const groupKey = ageGroupKey(avg.ageGroup ?? 0);

    if (! result[userKey]) {
      result[userKey] = emptyData(avg);
    }

    addData(result[userKey].values[groupKey], avg)
    addData(result[userKey].values[totalKey], avg)

    addData(result[totalKey].values[groupKey], avg)
    addData(result[totalKey].values[totalKey], avg)
  }

  for (const userKey in result) {
    const totalData = result[userKey].values[totalKey];

    for (const groupKey of [ageGroupKey(1), ageGroupKey(2), ageGroupKey(3), ageGroupKey(4), ageGroupKey(5), totalKey]) {
      const data = result[userKey].values[groupKey];

      if (data.inspected.count) {
        data.inspected.rate = data.inspected.count / result[totalKey].values[totalKey].inspected.count;
        data.averageAge.rate = data.averageAge.count / data.inspected.count;
        data.rejected.rate = data.rejected.count / data.inspected.count;
        data.severity1.rate = data.severity1.count / data.inspected.count;
        data.severity2.rate = data.severity2.count / data.inspected.count;

        if (groupKey !== totalKey) {
          data.rejected.level = getRefLevel(groupKey, 'rejected', data.rejected.rate * 100);
          data.severity1.level = getRefLevel(groupKey, 'severity1', data.severity1.rate * 100);
          data.severity2.level = getRefLevel(groupKey, 'severity2', data.severity2.rate * 100);
          data.avgLevel.level = (data.rejected.level + data.severity1.level + data.severity2.level) / 3;

          totalData.rejected.level = (totalData.rejected.level ?? 0) + data.rejected.level * data.inspected.count / totalData.inspected.count;
          totalData.severity1.level = (totalData.severity1.level ?? 0) + data.severity1.level * data.inspected.count / totalData.inspected.count;
          totalData.severity2.level = (totalData.severity2.level ?? 0) + data.severity2.level * data.inspected.count / totalData.inspected.count;
          totalData.avgLevel.level = (totalData.rejected.level + totalData.severity1.level + totalData.severity2.level) / 3;

          data.rejected.refRate = getRefRate(groupKey, 'rejected', 0);
          data.severity1.refRate = getRefRate(groupKey, 'severity1', 0);
          data.severity2.refRate = getRefRate(groupKey, 'severity2', 0);
        }
      }
    }

    totalData.rejected.level = totalData.rejected.level ?? null;
    totalData.severity1.level = totalData.severity1.level ?? null;
    totalData.severity2.level = totalData.severity2.level ?? null;
    totalData.avgLevel.level = totalData.avgLevel.level ?? null;
  }

  return result;
}
