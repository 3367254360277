import React, { useState } from 'react';
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import FaultItem from './FaultItem';
import FaultDetails from './FaultDetails';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { updateFaultInformation, openAddFault } from '../../../reducers/jobReducer';
import { setNotification } from '../../../reducers/notificationReducer';
import { getFaultCodePrefix, getFaultCodeLocation } from '../../../lib/faultFunctions';

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 40,
    flex: 1,
  },
  deleteAllButton: {
    marginTop: 16,
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
    },
  },
}));

function FaultList() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedChainId = useSelector(state => state.chains.selectedChainId);
  const job = useSelector(state => state.jobs.job);
  const [selectedFault, setSelectedFault] = useState(null);
  const [deleteAll, setDeleteAll] = useState(false);

  const handleOpenFault = (fault) => () => setSelectedFault(fault);

  const openFaultDetails = (fault) => {
    dispatch(openAddFault({
      addFaultCodePrefix: getFaultCodePrefix(fault),
      addFaultLocationId: getFaultCodeLocation(fault),
      addFaultSeverity: fault.severityLevel,
      addFaultAdditionalInformation: fault.additionalInformation,
      addFaultDeadline: fault.deadline,
    }));
    setSelectedFault(null);
  };

  // No job.
  if (!job) {
    return null;
  }

  // Sorted job faults.
  const jobFaults = [...(job.faultInformation || [])];
  jobFaults.sort((fault1, fault2) => fault2.severityLevel - fault1.severityLevel);

  // Sorted previous job faults.
  const previousJobFaults = [...(job.previousFaultInformation || [])];
  previousJobFaults.sort((fault1, fault2) => fault2.severityLevel - fault1.severityLevel);

  const handleDeleteFault = (faultToDelete) => {
    const fault = faultToDelete ? faultToDelete : selectedFault;
    let data = [];  // Removes all faults.
    if ('all' !== faultToDelete) {
      // Remove a specific fault. No confirmation dialog is showed.
      data = jobFaults.filter(f => f.faultCode !== fault.faultCode);
    }

    // Update job.
    dispatch(updateFaultInformation({
      chainId: selectedChainId,
      jobId: job.id,
      data,
    }))
      .then(unwrapResult)
      .then(() => {
        dispatch(setNotification({ message: t('faults.deleted') }));
        setSelectedFault(null);
        setDeleteAll(false);
      })
      .catch((error) => {
        console.warn('Failed to delete fault', error);
        dispatch(setNotification({ message: t('faults.deletionFailed'), type: 'error' }));
      });
  };

  const handleDeleteAllFaults = () => handleDeleteFault('all');

  const handleAddPreviousFault = (faultToAdd) => {
    let data = [...jobFaults, {...faultToAdd, deadline: faultToAdd.severityLevel === 1 ? dayjs().add(1, 'month').format('DD.MM.YYYY') : null}];
    // Update job.
    dispatch(updateFaultInformation({
      chainId: selectedChainId,
      jobId: job.id,
      data,
    }))
      .then(unwrapResult)
      .then(() => {
        dispatch(setNotification({ message: t('faults.added') }));
        setSelectedFault(null);
        setDeleteAll(false);
      })
      .catch((error) => {
        dispatch(setNotification({ message: t('faults.addFailed'), type: 'error' }));
    });
  }

  return (
    <>
      <div className={classes.list}>
        {jobFaults.map((fault) => (
          <FaultItem
            key={fault.faultCode}
            fault={fault}
            onClick={handleOpenFault(fault)}
            onDelete={() => handleDeleteFault(fault)}
          />
        ))}
      {jobFaults.length > 0 && <Button
        variant='outlined'
        color='primary'
        onClick={() => setDeleteAll(true)}
        className={classes.deleteAllButton}
      >
        {t('faults.deleteAllText')}
      </Button>}
      </div>
      <div className={classes.list}>
        {previousJobFaults.length > 0 && <h3>{t('faults.previous')}</h3>}
        {previousJobFaults.map((fault) => (
          <FaultItem
            key={fault.faultCode}
            fault={fault}
            onClick={handleOpenFault(fault)}
            onAdd={() => handleAddPreviousFault(fault)}
          />
        ))}
      </div>
      <FaultDetails
        open={Boolean(selectedFault)}
        onClose={() => setSelectedFault(null)}
        fault={selectedFault}
        onDelete={() => handleDeleteFault(selectedFault)}
        onEdit={openFaultDetails}
      />
      <ConfirmationDialog
        open={deleteAll}
        onClose={() => setDeleteAll(false)}
        onAccept={handleDeleteAllFaults}
        acceptText={t('delete')}
        text={t('faults.deleteConfirmation')}
      />
    </>
  );
}

export default FaultList;
